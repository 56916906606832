<template>
  <div class="main">
    <Header/>
    <div class="d-flex align-items-stretch h-100" style="ovflow:hidden">
      <section style="width:20%;min-width: 200px;max-width:300px;">
        <el-menu
          default-active="/"
          class="h-100"
          router
          @open="handleOpen"
          @close="handleClose">
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-cooperation"></i>
              <span>任务权限管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/" @click="$router.push('/searchtuflag')">权限查询</el-menu-item>
              <el-menu-item index="/addtuflag">添加权限</el-menu-item>
              <el-menu-item index="/addgrouptuflag">批量添加权限</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/roles-list">
            <i class="el-icon-user-solid"></i>
            <span slot="title">角色管理</span>
          </el-menu-item>
        </el-menu>
      </section>
      <section class="pl-5 pr-5" style="flex:1;overflow:auto;">
        <router-view v-if="isRouterAlive"/>
      </section>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  components: {
    Header
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  mounted: function () {
    this.$nextTick(function () {
    })
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 导航事件
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    // 导航事件
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>

<style>
body {
  padding-top: 3.5rem;
}
a:hover {
  text-decoration: none;
}
.tool-box {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.btn-action {
  padding-top: 1rem;
}
</style>
